import { TRPCClientError, createTRPCReact } from '@trpc/react-query'
import type {
  AppRouter,
  RouterInput as Input,
  RouterOutput as Output,
} from '@opinly/core/src/trpc/router'
import { PublicEnv } from '@/public-env'

export type TRPCInput = Input
export type TRPCOutput = Output

const retry = (failureCount: number, error: unknown) => {
  if (PublicEnv.NEXT_PUBLIC_STAGE !== 'production') {
    return false
  }

  if (!(error instanceof TRPCClientError)) {
    return false
  }

  const retryableCodes = new Set([
    'BAD_REQUEST',
    'TIMEOUT',
    'INTERNAL_SERVER_ERROR',
    'TOO_MANY_REQUESTS',
  ])

  if (!retryableCodes.has(error.data.code)) {
    return false
  }

  return failureCount < 3
}

export const trpc = createTRPCReact<AppRouter>({})

// export const trpc = createTRPCNext<AppRouter>({
//   config() {
//     return {
//       links: [
//         httpBatchLink({
//           /**
//            * If you want to use SSR, you need to use the server's full URL
//            * @link https://trpc.io/docs/ssr
//            **/
//           url: `${PublicEnv.NEXT_PUBLIC_API_URL}/trpc`,
//           // fetch: async (input, init?) => {
//           //   const fetch = getFetch()
//           //   return fetch(input, {
//           //     ...init,
//           //     credentials: 'include',
//           //   })
//           // },
//           // You can pass any HTTP headers you wish here
//           async headers() {
//             const { data } = await clerkClient.sessions.getToken()

//             return {
//               authorization: 'Bearer ' + data?.session?.access_token,
//             }
//           },
//         }),
//       ],
//       queryClientConfig: {
//         defaultOptions: {
//           queries: {
//             retry,
//           },
//         },
//       },
//     }
//   },
//   /**
//    * @link https://trpc.io/docs/ssr
//    **/
//   ssr: false,
// })

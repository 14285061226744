import type { NextEnv } from '../../types'

export const PublicEnv: NextEnv = {
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string,
  NEXT_PUBLIC_STAGE: process.env.NEXT_PUBLIC_STAGE as string,
  NEXT_PUBLIC_MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE as string,
  NEXT_PUBLIC_LINKEDIN_PARTNER_ID: process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID as string,
  NEXT_PUBLIC_GOOGLE_TAG: process.env.NEXT_PUBLIC_GOOGLE_TAG as string,
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY as string,
  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL as string,
  NEXT_PUBLIC_JWT_TEMPLATE: process.env.NEXT_PUBLIC_JWT_TEMPLATE as string,
  NEXT_PUBLIC_BUCKET_URL: process.env.NEXT_PUBLIC_BUCKET_URL as string,
  NEXT_PUBLIC_RECEIVE_EMAIL_DOMAIN: process.env.NEXT_PUBLIC_RECEIVE_EMAIL_DOMAIN as string,
  NEXT_PUBLIC_SLACK_CLIENT_ID: process.env.NEXT_PUBLIC_SLACK_CLIENT_ID as string,
  NEXT_PUBLIC_CLERK_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL as string,
  NEXT_PUBLIC_CLERK_SIGN_UP_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_UP_URL as string,
  NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL: process.env
    .NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL as string,
  NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL: process.env
    .NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL as string,
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY as string,
  NEXT_PUBLIC_MIXPANEL_API_KEY: process.env.NEXT_PUBLIC_MIXPANEL_API_KEY as string,
  NEXT_PUBLIC_FACEBOOK_PIXEL_ID: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string,
}

if (process.env.NODE_ENV === 'development') {
  Object.entries(PublicEnv).forEach(([key, value]) => {
    if (value === undefined) {
      throw new Error(`Missing environment variable ${key}`)
    }
  })
}

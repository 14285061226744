import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/Landing.css'
import '../styles/globals.css'
import { Toaster } from 'sonner'
import { trpc } from '../utils/trpc'
import { PublicEnv } from '@/public-env'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { DefaultSeo } from 'next-seo'
import { OPINLY_COLOR } from '@opinly/core/src/colors'
import {
  ClerkProvider as ClerkProviderNext,
  useAuth,
  useOrganization,
  useUser,
  GoogleOneTap,
} from '@clerk/nextjs'
import { TRPCClientError, httpBatchLink } from '@trpc/react-query'
import { PersistQueryClientProvider, Persister } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google'
import Script from 'next/script'
import posthog from 'posthog-js'
import { PostHogProvider, usePostHog } from 'posthog-js/react'
import mixpanel from 'mixpanel-browser'
import * as fbq from '@/lib/facebook-pixel'
import { useProfile } from '@/hooks/user/use-profile'
import { useRouteProtection } from '@/hooks/use-route-protection'
import { useAnalytics } from '@/hooks/analytics'

let persister: Persister

if (typeof window !== 'undefined') {
  mixpanel.init(PublicEnv.NEXT_PUBLIC_MIXPANEL_API_KEY, {
    debug: PublicEnv.NEXT_PUBLIC_STAGE !== 'production',
    track_pageview: true,
    persistence: 'localStorage',
  })

  posthog.init(PublicEnv.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: 'https://d2j4if2ojjjt96.cloudfront.net',
    person_profiles: 'identified_only',
    ui_host: 'https://us.posthog.com',
    enable_recording_console_log: false,
  })

  persister = createSyncStoragePersister({
    storage: window?.localStorage,
  })
}

function MyApp({ Component, pageProps }: AppProps) {
  const { user } = useUser()

  const { organization, isLoaded: organizationLoaded } = useOrganization()

  useRouteProtection()
  useAnalytics()

  return (
    <main
      // className={`${dancingScript.variable} scroll-smooth antialiased [font-feature-settings:'ss01']`}
      className={`scroll-smooth antialiased [font-feature-settings:'ss01']`}
    >
      <DefaultSeo
        defaultTitle="Opinly"
        title="Opinly"
        description="Opinly is an AI powered competitor monitoring and analysis platform for companies."
        themeColor={OPINLY_COLOR}
        twitter={{
          site: 'https://www.opinly.ai',
          cardType: 'summary_large_image',
          handle: '@opinlyai',
        }}
        openGraph={{
          type: 'website',
          url: 'https://www.opinly.ai',
          siteName: 'Opinly',
          description:
            'Opinly is an AI powered competitor monitoring and analysis platform for companies.',
          title: 'Opinly',
          images: [
            {
              url: 'https://www.opinly.ai/files/open-graph-1.png',
              alt: 'Opinly',
              type: 'image/png',
            },
          ],
        }}
      />
      <ReactQueryDevtools initialIsOpen={false} buttonPosition={'bottom-right'} />
      <Toaster richColors expand={true} duration={6000} closeButton={true} />
      <Component {...pageProps} />
      <Script
        src="https://js.hs-scripts.com/46467431.js"
        id="hs-script-loader"
        strategy="afterInteractive"
      />
      {/* <Script src={`https://www.googletagmanager.com/gtag/js?id=G-03WJWKHVGM`} />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-03WJWKHVGM');
        `}
      </Script> */}
    </main>
  )
}

function TRPCWrapper(props: AppProps) {
  const auth = useAuth()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount: number, error: unknown) => {
              if (error instanceof TRPCClientError) {
                if (PublicEnv.NEXT_PUBLIC_STAGE !== 'production') {
                  return false
                }

                const retryableCodes = new Set([
                  'BAD_REQUEST',
                  'TIMEOUT',
                  'INTERNAL_SERVER_ERROR',
                  'TOO_MANY_REQUESTS',
                  'FORBIDDEN',
                ])

                if (!retryableCodes.has(error.data.code)) {
                  return false
                }
              }

              return failureCount < 3
            },
          },
        },
      })
  )

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           **/
          url: `${PublicEnv.NEXT_PUBLIC_API_URL}/trpc`,
          // fetch: async (input, init?) => {
          //   const fetch = getFetch()
          //   return fetch(input, {
          //     ...init,
          //     credentials: 'include',
          //   })
          // },
          // You can pass any HTTP headers you wish here
          async headers() {
            const token = await auth.getToken({
              template: PublicEnv.NEXT_PUBLIC_JWT_TEMPLATE,
            })

            return {
              Authorization: 'Bearer ' + token,
            }
          },
        }),
      ],
    })
  )

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister,
        }}
      >
        <MyApp {...props} />
      </PersistQueryClientProvider>
    </trpc.Provider>
  )
}

export default function AppWrapper(props: AppProps) {
  return (
    <>
      <PostHogProvider client={posthog}>
        <ClerkProviderNext
          {...props.pageProps}
          appearance={{
            variables: {
              colorPrimary: 'rgb(24 24 27)',
              colorText: 'rgb(24 24 27)',
            },
          }}
        >
          <GoogleOneTap
            signInForceRedirectUrl={'/dashboard'}
            signUpForceRedirectUrl={'/dashboard'}
            cancelOnTapOutside={false}
            appearance={{
              variables: {
                colorPrimary: 'rgb(24 24 27)',
                colorText: 'rgb(24 24 27)',
              },
            }}
          />
          <TRPCWrapper {...props} />
        </ClerkProviderNext>
        <GoogleTagManager gtmId="GTM-5K5PC9WF" />
        <Script id="default-c">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
    
          gtag('consent', 'default', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
          });
          `}
        </Script>
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
          }}
        />
      </PostHogProvider>
    </>
  )
}

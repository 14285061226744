import { useEffect, useState } from 'react'
import { useUser } from '@clerk/nextjs'
import * as fbq from '@/lib/facebook-pixel'
import { useRouter } from 'next/router'
import { trpc } from '../utils/trpc'
import { useAuth, useOrganization } from '@clerk/nextjs'
import { sendGTMEvent } from '@next/third-parties/google'
import { usePostHog } from 'posthog-js/react'
import mixpanel from 'mixpanel-browser'
import { useProfile } from '@/hooks/user/use-profile'

export const useAnalytics = () => {
  const { user, isLoaded: userLoaded } = useUser()
  const posthog = usePostHog()
  const router = useRouter()

  const profile = useProfile()

  const auth = useAuth()

  const intercom = trpc.profile.intercom.useQuery(undefined, {
    enabled: Boolean(user),
  })

  const { organization, isLoaded: organizationLoaded } = useOrganization()

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    if (!userLoaded) {
      return
    }

    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = () => {
      fbq.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (!userLoaded) {
      return
    }

    if (user) {
      window.fbq('init', fbq.FB_PIXEL_ID, {
        em: user.primaryEmailAddress?.emailAddress.toLowerCase(),
        fn: user.firstName?.toLowerCase(),
        ln: user.lastName?.toLowerCase(),
        ph: user.primaryPhoneNumber?.phoneNumber,
        external_id: user.id,
      })
    }
  }, [userLoaded])

  useEffect(() => {
    if (!user || !profile.data || !intercom.data || !auth.isLoaded || !organizationLoaded) return

    if (auth.actor) {
      return
    }

    const createdAtInSeconds = user.createdAt
      ? Math.floor(user.createdAt.getTime() / 1000)
      : undefined

    if (organization?.id) {
      posthog.group('company', organization.id, {
        name: organization.name,
        date_created: organization.createdAt.toLocaleDateString(),
      })
    }

    posthog.identify(user.id, {
      email: user.primaryEmailAddress?.emailAddress,
      name: user.fullName,
      is_subscribed: user.publicMetadata.isSubscribed,
      subscription_created_at: profile.data.subscriptionCreatedAt,
      plan_type: user.publicMetadata.planType,
      organizationId: organization?.id,
    })

    mixpanel.identify(user.id)

    mixpanel.people.set({
      $name: `${user.firstName} ${user.lastName}`,
      $email: user.primaryEmailAddress?.emailAddress,
      plan: user.publicMetadata.planType,
      is_subscribed: user.publicMetadata.isSubscribed,
      subscription_created_at: profile.data.subscriptionCreatedAt,
      plan_type: user.publicMetadata.planType,
      organizationId: organization?.id,
      // Add anything else about the user here
    })

    sendGTMEvent({
      event: 'setUserData',
      user_id: user.id,
      email_address: user.primaryEmailAddress?.emailAddress,
      phone_number: user.primaryPhoneNumber?.phoneNumber,
      full_name: user.fullName,
      first_name: user.firstName,
      last_name: user.lastName,
      is_subscribed: user.publicMetadata.isSubscribed,
      subscription_created_at: profile.data.subscriptionCreatedAt,
      plan_type: user.publicMetadata.planType,
      user_created_at: createdAtInSeconds,
      profile_url: user.imageUrl,
      intercom_hash: intercom.data,
    })
  }, [user, profile.data, intercom.data, organizationLoaded])
}
